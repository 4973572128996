<template>
  <div class="nftcenter">
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>

    <div class="nftcenter_sum" v-loading="loading">
      <div class="flex" style="align-items: start">
        <div class="sumbox">
          <div class="sumbox_title">{{ $t('lang.待产出星石') }}</div>
          <div class="sumbox_num"><span>{{ hold_summary.remain_production }}</span></div>
        </div>
        <img src="@/assets/imgsnowball/sumbox_img.png" class="sumbox_img" alt="" />
      </div>
      <div class="flex" style="justify-content: flex-end;">
        <!-- <div class="flex" style="transform: scale(0.8) translateX(-30px)">
          <div class="sumbox" style="margin-right: 23px">
            <div class="sumbox_title">{{ $t('lang.今日己产出') }}</div>
            <div class="sumbox_num"><span>{{ hold_summary.today_production }}</span> </div>
          </div>
          <div class="sumbox">
            <div class="sumbox_title">{{ $t('lang.累计已产出') }}</div>
            <div class="sumbox_num"><span>{{ hold_summary.total_Production }}</span></div>
          </div>
        </div> -->
        <div class="button" @click="gopath('nftbill')">{{ $t('lang.o104') }}</div>
      </div>
    </div>
    <div class="mission">
      <div class="mission_left">
        <div class="mission_top">
          <img src="@/assets/imgsnowball/gold-left.png" mode="" class="img"></img>
          <div class="mission_top_button" @click="gopath('c2c')">

            {{ $t('lang.去交易') }}
          </div>
        </div>
        <div class="mission_bottom">
          <div class="mission_bottom_line1">
            {{ banlace.SS.usable }}
          </div>
          <div class="mission_bottom_line2">
            <span> {{ $t('lang.账户可用星石') }}</span>

          </div>
        </div>
      </div>
      <div class="mission_rght">
        <div class="mission_top">
          <img src="@/assets/imgsnowball/gold-right.png" mode="" class="img"></img>

        </div>
        <div class="mission_bottom">
          <div class="mission_bottom_line1">
            {{ hold_summary.today_production + hold_summary.today_unclaimed }}
          </div>
          <div class="mission_bottom_line2">
            {{ $t('lang.今日总产出') }}
          </div>
        </div>
      </div>
    </div>
    <div class="lookboxs">
      <div class="lookbox">
        <!-- <div class="abs_look" @click="gopath('user')">{{ $t('lang.o105') }}</div> -->
        <div class="box">
          <div class="box_num">{{ summaryinfo.children_num }}</div>
          <img src="@/assets/imgsnowball/box1.png" alt="" />
          <div class="box_title">{{ $t('lang.团队总张数') }}</div>
        </div>
      </div>
      <div class="lookbox">
        <!-- <div class="abs_look" style="background: rgba(74, 181, 172, 1);" @click="gopath('engbill', 0)">{{ $t('lang.o105')
          }}</div> -->
        <div class="box" style="background: rgba(217, 255, 255, 1)">
          <div class="box_num">{{ summaryinfo.max_zone_num }}</div>
          <img src="@/assets/imgsnowball/box2.png" alt="" />
          <div class="box_title">{{ $t('lang.最大社区数') }}</div>
        </div>
      </div>
      <div class="lookbox">
        <!-- <div class="abs_look" style="background: rgba(22, 181, 117, 1);" @click="gopath('engbill', 1)">{{ $t('lang.o105')
          }}</div> -->
        <div class="box" style="background: rgba(193, 245, 224, 1)">
          <div class="box_num">{{ summaryinfo.other_zone_num }}</div>
          <img src="@/assets/imgsnowball/box3.png" alt="" />
          <div class="box_title">{{ $t('lang.o108') }}</div>
        </div>
      </div>
    </div>

    <!-- <div class="dotdlist">
      <div class="dotdlist_li" v-for="(item, index) in ranklist.endlist" :key="index">
        <div class="dotdlist_li_index">{{ index + 4 }}</div>
        <div class="dotdlist_li_box">
          <div class="dotdlist_li_box_left">{{ item.address }}</div>
          <div class="dotdlist_li_box_right">{{ item.share_cp }}</div>
        </div>
      </div>
    </div> -->
    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="nftlist" v-if="regionIndex == 0">
      <div class="nftlist_li" v-for="(item, index) in nftlistdata" :key="index">
        <div class="nftlist_li_cont">
          <img :src="basicUrlData + item.logo" alt="" class="li_img" />
          <div class="li_right">
            <div class="li_right_name">
              <span>{{ item.name }}</span>
              <span>({{ item.hold_limit }}/{{ item.hold_num }})</span>
            </div>
            <van-rate v-model="item.hold_num" readonly :size="10" :count="item.hold_limit" color="rgba(0, 186, 173, 1)"
              void-icon="star" void-color="rgba(112, 112, 112, 1)" />
            <div class="li_right_pop">{{ $t('lang.原始值') }}:{{ item.ss_price }}</div>
            <div class="li_right_pop" style="color: rgba(102, 180, 174, 1);">{{ $t('lang.产出值') }}:{{ item.production }}
            </div>
          </div>
        </div>
        <div class="nftlist_li_button button_active" v-if="item.can_buy" @click="paypopFun(item, 'buy')">{{
          $t('lang.o112') }}</div>
        <div class="nftlist_li_button" v-else-if="!item.can_buy && item.hold_num == 0">{{ $t('lang.o113') }}</div>
        <div class="nftlist_li_button button_active" v-else style="opacity: 0.6;">{{ $t('lang.o112') }}</div>
      </div>
    </div>
    <div class="nftlist" v-else-if="regionIndex == 1">
      <van-list v-model="holdlistIng.loading" :finished="holdlistIng.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdIng">
        <div class="nftlist_li" v-for="(item, index) in holdlistIng.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img :src="basicUrlData + item.nft_logo" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name">{{ $t('lang.o111') }}{{ item.production }}</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(0, 0, 0, 1); font-weight: 100; margin-bottom: 8px;">{{
                  $t('lang.o142') }} : {{ item.created_at }}</div>
              <div class="poptuct" style="margin-bottom: 8px;">
                <div style="color: rgba(0, 186, 173, 1); margin-right: 62px">
                  {{ $t('lang.o114') }} <br />
                  {{ item.production - item.remain_amount }}
                </div>
                <div>
                  {{ $t('lang.o115') }} <br />
                  {{ item.remain_amount }}
                </div>
              </div>

              <div
                style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;">
                <span>{{ $t('lang.o116') }}</span> <span style="color: rgba(102, 180, 174, 1);">{{ $t('lang.o143')
                  }}</span></div>
              <div style="width: 100%;">
                <van-progress :percentage="productionCom(item.production, item.production - item.remain_amount)"
                stroke-width="8" color="rgba(0, 186, 173, 1)" track-color="none"
                style="border: 1px solid rgba(171, 217, 214, 1); " :show-pivot="false" />
              </div>
           
            </div>
          </div>
        </div>
      </van-list>

    </div>
    <div class="nftlist" v-else-if="regionIndex == 2">
      <van-list v-model="holdlistEnd.loading" :finished="holdlistEnd.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdEnd">
        <div class="nftlist_li" v-for="(item, index) in holdlistEnd.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img :src="basicUrlData + item.nft_logo" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name" style="display: flex; align-items: center; justify-content: space-between;">
                <span>{{ $t('lang.o111') }}{{ item.production }}</span>
                <div>
                  <van-tag round type="primary" color="rgba(102, 180, 174, 1)"
                    style="margin-left: 7px;font-size: 12px; padding: 2px 10px;" @click="paypopFun(item, 'sell')"
                    v-if="item.activation_status == '0'"> {{ $t('lang.激活') }}</van-tag>
                  <van-tag round type="primary" color="rgba(207, 207, 207, 1)"
                    style="margin-left: 7px;font-size: 12px; padding: 2px 10px;"
                    v-else-if="item.activation_status == '1'"> {{ $t('lang.已激活') }}</van-tag>
                  <van-tag round type="primary" plain style="margin-left: 7px;font-size: 12px; padding: 2px 10px; "
                    text-color="rgba(173, 173, 173, 1)" v-else-if="item.sactivation_status == 2"> {{ $t('lang.未激活')
                    }}</van-tag>
                </div>
              </div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(102, 180, 174, 1); font-weight: 100; margin-bottom: 8px;">{{
                  $t('lang.o142') }} : {{ item.created_at }}</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(102, 180, 174, 1); font-weight: 100; margin-bottom: 8px;">{{
                  $t('lang.到期时间') }} : {{ item.finished_at }}</div>
              <div class="poptuct" style="margin-bottom: 8px;">
                <div style="color: rgba(0, 186, 173, 1); margin-right: 62px">
                  {{ $t('lang.o114') }} <br />
                  {{ item.production - item.remain_amount }}
                </div>
                <div>
                  {{ $t('lang.o115') }} <br />
                  {{ item.remain_amount }}
                </div>
              </div>
              <div>
                <div
                  style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;">
                  <span>{{ $t('lang.o116') }}</span> <span>{{ $t('lang.o144') }}</span></div>
                <van-progress :percentage="productionCom(item.production, item.production - item.remain_amount)" stroke-width="8"
                  color="rgba(0, 186, 173, 1)" track-color="none" style="border: 1px solid rgba(171, 217, 214, 1)"
                  :show-pivot="false" />

              </div>
            </div>
          </div>
        </div>
      </van-list>

    </div>
    <van-popup v-model="payshow" position="bottom" round closeable>
      <div class="paypop">
        <div class="title" v-if="nftItemdata.liststatus == 'buy'">
          {{ $t('lang.o117') }}
        </div>
        <div class="title" v-else>
          {{ $t('lang.你需要销毁') }}
        </div>
        <div class="paypop_cont">
          <div class="paypop_checks" v-if="nftItemdata.liststatus == 'buy'">
            <!-- <div class="paypop_checks_box" @click="checkdataFun('0')">
              <span>{{ nftItemdata.one_price }} {{ banlace.hap.token }}</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '0'">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div>
            </div> -->
            <div class="paypop_checks_box" @click="checkdataFun('1')">
              <span>{{ nftItemdata.ss_price }} {{ banlace.SS.token }}</span>
              <!-- <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '1'">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div> -->
            </div>
            <!-- <div class="paypop_checks_box" @click="checkdataFun('2')">
              <span>{{ nftItemdata.u_price }} {{ banlace.hapc.token }}</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '2'">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div>
            </div> -->
          </div>
          <div class="paypop_checks" v-else>
        
         
            <div class="paypop_checks_box" v-if="nftlistdata.length > 0">
              <span>{{ nftlistdata[0].activation_pay }} {{ banlace.SS.token }}</span>
             
            </div>
          </div>
          <div class="banlace">
            <img src="@/assets/imgsnowball/banlce.png" alt="">
            <div class="banlace_box" v-if="nftItemdata.liststatus == 'buy'">
              <div style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">{{
                $t('lang.o118') }}</div>
              <!-- <div style="display: flex;">
                <div style="width: 80px;">{{ banlace.hap.token }}{{ $t('lang.o119') }}：</div> {{ banlace.hap.usable }}
              </div>
              <div style="display: flex;">
                <div style="width: 80px;">{{ banlace.hapc.token }}{{ $t('lang.o119') }}：</div> {{ banlace.hapc.usable }}
              </div> -->
              <div style="display: flex;">
                <div style="width: 80px;">{{ banlace.SS.token }}{{ $t('lang.o119') }}：</div> {{ banlace.SS.usable }}
              </div>
            </div>
            <div class="banlace_box" v-else>
              <div style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">{{
                $t('lang.o118') }}</div>
              <div style="display: flex;">
                <div style="width: 80px;">{{ banlace.SS.token }}{{ $t('lang.o119') }}：</div> {{ banlace.SS.usable }}
              </div>
            </div>
          </div>
          <div class="button" @click="paybuyFun" v-if="nftItemdata.liststatus == 'buy'">{{ $t('lang.o120') }}</div>
          <div class="button" @click="nftholdactivation" v-else>{{ $t('lang.确定销毁') }}</div>
        
        </div>
      </div>
    </van-popup>
    <van-popup v-model="paystatus" position="bottom" round>
      <div class="paystatus">
        <img src="@/assets/imgsnowball/paystatus_success.png" alt="">
        <div class="paystatus_text">{{ $t('lang.d195') }}</div>
        <van-button type="primary" round class="paystatus_button" @click="paystatusFun">

          <span> {{ $t('lang.d197') }}</span>
        </van-button>
       
      </div>
    </van-popup>
  </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import basicUrl from "@/config/configUrl/configUrl";
import Web3 from 'web3';

export default {
  data() {
    return {
      paystatus: false,
      loading: true,
      functionOption: [{ name: 'lang.星石矿卡' }, { name: 'lang.工作中' }, { name: 'lang.已完成' }],
      regionIndex: '0',
      value: 1,
      slidervalue: 20,
      nftlistdata: [],
      nftItemdata: {},
      basicUrlData: '',
      payshow: false,
      checkdata: '1',
      banlace: {
        hap: {},
        hapc: {},
        SS:{}
      },
      hold_summary: {},
      ranklist: {
        startlist: [],
        endlist: [],
      },
      summaryinfo: {},
      holdlistIng: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          page_size: 10,
          status: '1'
        }

      },
      holdlistEnd: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          page_size: 10,
          status: '0'
        }

      }

    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {

      this.init()
    },
    lang() {
      this.init()
    }
  },
  components: {},
  created() {
    this.init()
  },
  mounted() { },
  methods: {
    init() {
      if (this.account) {

        this.loading = true
        this.basicUrlData = basicUrl.http_url
        this.holdlistIng = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            page_size: 10,
            status: '1'
          }

        }
        this.holdlistEnd = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            page_size: 10,
            status: '0'
          }

        }
        this.nftlist()
        this.getbanlace()
        // this.nftholdlistiIng()
        // this.nftholdlistiEnd()

        this.nftsummaryinfo()
        this.$nextTick(() => {

        })
      }

    },
    paystatusFun() {
      this.paystatus = false
    },

    nftsummaryinfo() {
      nft.nftsummaryinfo(this.account).then(res => {

        this.hold_summary = res.data
        this.loading = false
      })
      nft.nftteaminfo(this.account).then(res => {
        this.summaryinfo = res.data.level_info
      })
    },
    productionCom(production, remain_amount) {
      return parseFloat((remain_amount / production) * 100)
    },
    roundToInteger(num) {
      return parseFloat(num).toFixed(3);
    },
    onLoadholdIng() {
      this.nftholdlistiIng()
    },
    onLoadholdEnd() {
      this.nftholdlistiEnd()
    },

    nftholdlistiIng() {
      this.holdlistIng.loading = true
      nft.nftholdlist(this.holdlistIng.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistIng.info.page == 1) {
            this.holdlistIng.list = res.data
          } else {
            this.holdlistIng.list = this.holdlistIng.list.concat(res.data)
          }
          if (res.data.length < this.holdlistIng.info.page_size) {
          
            this.holdlistIng.finished = true
          } else {
            this.holdlistIng.info.page++
          }

        }
        this.holdlistIng.loading = false
      })
    },
    nftholdlistiEnd() {
      this.holdlistEnd.loading = true
      nft.nftholdlist(this.holdlistEnd.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistEnd.info.page == 1) {
            this.holdlistEnd.list = res.data
          } else {
            this.holdlistEnd.list = this.holdlistEnd.list.concat(res.data)
          }
          if (res.data.length < this.holdlistEnd.info.page_size) {
           
            this.holdlistEnd.finished = true
          } else {
            this.holdlistEnd.info.page++
          }

        }
        this.holdlistEnd.loading = false
      })
    },
    getbanlace() {
      nft.walletinfobykey({ token_key: 'ONE' }, this.account).then(res => {
        this.banlace.hap = res.data
      })
      nft.walletinfobykey({ token_key: 'USDT' }, this.account).then(res => {
        this.banlace.hapc = res.data
      })
      nft.walletinfobykey({ token_key: 'SS' }, this.account).then(res => {
        this.banlace.SS = res.data
      })
    },
    checkdataFun(index) {

      this.checkdata = index
    },
    paypopFun(item, status) {
      this.nftItemdata = item
      this.nftItemdata.liststatus = status
      this.payshow = true
    },
    async paybuyFun() {
       this.payshow = false
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Buy'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        nft_id: this.nftItemdata.id,
        type: parseInt(this.checkdata),
        signature: sign
      }
      nft.nftholdbuy(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
          this.paystatus = true
          this.payshow = false
        }
        this.$toast(res.msg)
      })
    },
    async nftholdactivation() {
      this.payshow = false
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Activation'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        hold_id: this.nftItemdata.id,
        signature: sign
      }
      nft.nftholdactivation(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
          this.paystatus = true
          this.payshow = false
        }
        this.$toast(res.msg)
      })
    },
    nftlist() {
      nft.nftlist(this.account).then((res) => {
        if (res.code == 200) {
          this.nftlistdata = [res.data]
        }
      });
    },
    toprouter() {
      this.$router.go(-1);
    },

    switchovers(index) {
      this.regionIndex = index;

      switch (this.regionIndex) {
        default:
          break;
      }

    },
    gopath(router, i) {
      switch (router) {
        case 'nftbill':
          this.$router.push('/nftbill')
          break;
        case 'engbill':

          this.$router.push({ path: 'engbill', query: { regionIndex: i } })
          break;
        case 'user':
          {
            this.regionIndex = 1;
            var element = document.getElementById('myElementId');
            element.scrollIntoView({ behavior: "smooth" });
          }
          break;
        default: {
          this.$router.push({ name: router })
        }
          break;
      }
    }
  },
};
</script>
<style scoped lang='less'>
.paystatus {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 347px;
  padding: 51px 20px;

  img {
    width: 60px;
    height: 60px;
  }

  .paystatus_button {
    border: none;
    width: 100%;
    border-radius: 110px;
    background: rgba(102, 180, 174, 1);
    font-size: 16px;
    font-weight: 700;
    color: #000 !important;
  }

  .paystatus_text {

    margin-top: 18px;
    margin-bottom: 75px;
    font-size: 18px;
    font-weight: 700;
    color: rgba(34, 34, 34, 1);
  }
}

.paypop {
  width: 100%;

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 19px 20px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);
  }

  .paypop_cont {
    padding: 40px 21px;

    .button {
      margin-top: 23px;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(102, 180, 174, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }

    .banlace {
      padding-bottom: 7px;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .banlace_box {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
      }
    }

    .paypop_checks {
      width: 100%;

      .paypop_checks_box {
        padding: 14px 19px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;

        border: 1px solid rgba(230, 230, 230, 1);
        margin-bottom: 14px;

        .checkbox {
          width: 14px;
          height: 14px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.nftcenter {
  padding-top: 52px;
  padding-bottom: 44px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nftlist {
  width: 100%;
  padding: 0 20px;

  .nftlist_li {
    padding: 25px 16px 20px 16px;
    width: 100%;
    opacity: 1;
    border-radius: 10px;

    margin-bottom: 20px;
    border: 1px solid rgba(240, 240, 240, 1);

    .button_active {
      border-radius: 53px !important;
      background: rgba(102, 180, 174, 1) !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    .nftlist_li_button {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      height: 44px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(0, 0, 0, 1);
    }

    .nftlist_li_cont {
      display: flex;
      align-items: center;

      .li_right {
        .poptuct {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
        }

        .li_right_name {
          font-size: 14px;
          font-weight: 700;
          color: rgba(0, 0, 0, 1);
          margin-bottom: 3px;
        }

        .li_right_pop {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.6);
          margin-top: 6px;
        }
      }

      .li_img {
        margin-right: 18px;
        width: 84px;
        height: 110px;
      }
    }
  }
}

.functional_region {
  margin-top: 36px;
  padding: 10px 20px 0;
  padding-top: 0;

  .region {
    display: flex;
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    border-radius: 30px;
    padding: 3px;
    border: 1px solid rgba(102, 180, 174, 0.4);
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(158, 156, 163, 1);
    font-weight: 600;
    // background:  rgba(69, 131, 255, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      // width: 192rpx;
      padding: 10px 0;
    }

    .special {
      // width: 50%;
      color: #fff;
      background: rgba(102, 180, 174, 1);
      // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
      border-radius: 34px;
    }
  }
}

.dotdlist {
  border-radius: 31px;
  padding: 0 20px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);

  .dotdlist_li {
    margin-bottom: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .dotdlist_li_index {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      margin-right: 18px;
    }

    .dotdlist_li_box {
      border-radius: 31px;
      padding: 13px 19px;
      width: 274px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(27, 31, 46, 1);
    }
  }
}

.dotdbox {
  margin-top: 34px;
  margin-bottom: 14px;
  width: 100%;
  background: url('~@/assets/imgsnowball/dotd_bg.png') no-repeat;
  background-size: cover;

  .dotdbox_title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dotdbox_cont {
    border-radius: 4px;
    margin: 0 auto;
    width: 339px;
    height: 199px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(27, 31, 46, 0.9);

    border: 1px solid rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .dotdbox_cont_level {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .level_user {
        margin-top: 6px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
      }

      .level_index {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }

      .level_logo {
        margin-top: 6px;
        margin-bottom: 5px;
        width: 32px;
        height: 36px;
      }

      .level_pop {
        color: rgba(0, 0, 0, 1);
        border-radius: 26px;
        background: rgba(102, 206, 253, 1);
        text-align: center;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }
}

.lookboxs {
  display: flex;
  width: 335px;
  margin: 0 auto;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;

  .lookbox {
  padding-top: 6px;
    position: relative;
    background-clip: padding-box;
    width: 103.77px;
    padding-bottom: 6px;
    border: 1px solid rgba(229, 229, 229, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .abs_look {
      position: absolute;
      right: 6px;
      top: 6px;
      padding: 3px;
      border-radius: 4px;
      background: rgba(179, 179, 179, 1);
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
    }

    .box {
      width: 88.09px;
      padding: 16px 0 9px 0;
      display: flex;
      border-radius: 8px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: rgba(247, 247, 247, 1);
    }

    .box_num {
      font-size: 18px;
      color: rgba(0, 0, 0, 1);
    }

    .box_title {
      font-size: 12px;
      color: rgba(0, 0, 0, 1);
    }

    img {
      margin-top: 10px;
      margin-bottom: 14px;
      width: 26px;
      height: 26px;
    }
  }
}

.nftcenter_sum {
  padding: 13px 17px;
  width: 335px;

  margin: 0 auto;
  border-radius: 8px;
  background: linear-gradient(138.26deg, rgba(102, 180, 174, 1) 0%, rgba(102, 180, 174, 1) 100%);

  .button {
    width: 53px;
    height: 30px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(102, 180, 174, 1);
  }

  .sumbox_img {
    width: 150.32px;
    height: 85px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sumbox {
    font-size: 12px;

    .sumbox_title {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 4px;
    }

    .sumbox_num {
      font-size: 28px;
      color: #fff;
    }
  }
}

.mission {

  display: flex;
  justify-content: space-between;
  margin: 15px auto;
  width: 335px;

  .mission_bottom {
    .mission_bottom_line1 {
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }

    .mission_bottom_line2 {
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
    }
  }

  .mission_top {
    display: flex;
    justify-content: space-between;

    .img {
      width: 80px;
      height: 80px;
    }

    .mission_top_button {
      width: 50px;
      height: 26px;
      opacity: 1;
      border-radius: 42px;

      border: 1px solid rgba(74, 181, 172, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(74, 181, 172, 1);
      font-size: 12px;
    }
  }

  .mission_left {
    display: flex;
    flex-direction: column;
    width: 46%;
    border-radius: 10rpx;

    opacity: 1;
    border-radius: 16px;
    background: linear-gradient(134.68deg, rgba(250, 255, 255, 1) 0%, rgba(214, 255, 255, 1) 100%);
    padding: 15px 10px;




  }

  .mission_rght {
    display: flex;
    flex-direction: column;
    width: 46%;


    opacity: 1;
    border-radius: 16px;
    background: linear-gradient(134.68deg, rgba(250, 255, 255, 1) 0%, rgba(214, 255, 255, 1) 100%);
    padding: 15px 10px;


    .draw_button {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      margin-top: 30rpx;

      .quantity {
        font-size: 36rpx;
        font-weight: bold;
        color: rgba(255, 163, 26, 1);
      }

      .draw {
        display: block;
        font-size: 24rpx;
        border-radius: 40rpx;
        background: rgba(44, 130, 250, 1);
        padding: 10rpx 30rpx;

        &.disabled {
          background-color: #888888;
        }
      }
    }
  }
}
</style>