<template>
    <div class="c2c">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="c2c_box" v-loading="assetsLoading">
            <div class="c2c_box_line"
                style="color: rgba(255, 255, 255, 0.4); padding-bottom: 11px  ; border-bottom: 1px solid rgba(85, 157, 158, 1);">
                <div>{{ $t('lang.可交易') }} </div>
                <div>{{ $t('lang.交易中') }} </div>
                <div>{{ $t('lang.类型') }} </div>
            </div>
            <div class="c2c_box_line" v-for="(item, index) in assetsList" :key="index">
                <div>{{ item.usable }}</div>
                <div>{{ item.un_deal }}</div>
                <div>{{ item.token }}</div>
            </div>
        </div>
        <div class="flex">
            <div class="tabs">
                <div :class="tabsactive == 0 ? ['tabs_item', 'tabs-active'] : ['tabs_item']" @click="tabsactiveFun(0)">
                    {{ $t('lang.买单') }} </div>
                <!-- <div :class="tabsactive == 1 ? ['tabs_item', 'tabs-active'] : ['tabs_item']" @click="tabsactiveFun(1)">
                    {{ $t('lang.卖单') }} </div> -->
            </div>
            <div class="tabs_right" @click="Goc2c2Order">{{ $t('lang.我的订单') }}</div>
        </div>
        <div class="checks">
            <van-dropdown-menu active-color="rgba(102, 180, 174, 1)" :close-on-click-outside="false">
                <van-dropdown-item v-model="value1" :title="title1" ref="dropdownitem">

                    <div class="dropdown_item">

                        <div class="dropdown_item_line">
                            <input type="number" :placeholder="$t('lang.请输入最小价格')" v-model="filter.minInput">
                            <div>-</div>
                            <input type="number" :placeholder="$t('lang.请输入最大价格')" v-model="filter.maxInput">
                        </div>
                        <div class="dropdown_item_line" style="font-size: 14px;">
                            <span> {{ $t('lang.最小价格：') }} {{ filter.minInput }} </span>
                            <span>{{ $t('lang.最大价格：') }} {{ filter.maxInput }}</span>
                        </div>
                        <div class="dropdown_item_line" style="justify-content: flex-end;">
                            <van-button type="primary" color="rgba(102, 180, 174, 1)" style="margin-right: 20px;"
                                @click="filterPriceFun"> <span style="color: #fff;"> {{ $t('lang.确定') }}</span>
                            </van-button>
                            <van-button type="primary" plain color="rgba(102, 180, 174, 1)" style="margin-right: 20px;"
                                @click="filtercanncel"> <span style="color: rgba(102, 180, 174, 1);"> {{ $t('lang.取消')
                                    }}</span>
                            </van-button>
                            <van-button type="danger" @click="filterclear"> <span style="color: #fff;">{{
                $t('lang.清除价格') }}</span>
                            </van-button>
                        </div>

                    </div>
                </van-dropdown-item>
                <van-dropdown-item v-model="value2" :title="title2" ref="dropdownitemTime">
                    <div class="dropdown_item">
                        <div class="dropdown_item_line">
                            <div class="dropdown_item_line_box" @click="timerChangeShowFun('min')">
                                {{ filterTimer.minTime != '' ? filterTimer.minTime : $t('lang.选择发布时间') }}

                            </div>
                            <div>-</div>
                            <div class="dropdown_item_line_box" @click="timerChangeShowFun('max')">
                                {{ filterTimer.maxTime != '' ? filterTimer.maxTime : $t('lang.选择发布时间') }}
                            </div>
                        </div>
                        <div class="dropdown_item_line" style="justify-content: flex-end;">
                            <van-button type="primary" color="rgba(102, 180, 174, 1)" style="margin-right: 20px;"
                                @click="filterTimeFun"> <span style="color: #fff;">{{ $t('lang.确定') }}</span>
                            </van-button>
                            <van-button type="primary" plain color="rgba(102, 180, 174, 1)" style="margin-right: 20px;"
                                @click="filtercanncelTime"> <span style="color: rgba(102, 180, 174, 1);">{{
                $t('lang.取消') }}</span>
                            </van-button>
                            <van-button type="danger" @click="filterclearTime"> <span style="color: #fff;">{{
                $t('lang.清除时间') }}</span>
                            </van-button>
                        </div>
                    </div>

                </van-dropdown-item>
            </van-dropdown-menu>
        </div>
        <div class="list">
            <van-list v-model="c2clist.loading" :finished="c2clist.finished" :finished-text="`${$t('lang.d145')}`"
                @load="onLoadList">
                <div class="li" v-for="(item, index) in c2clist.list" :key="index">
                    <div class="li_item">
                        <div class="li_item_left" style="color: rgba(0, 0, 0, 1);font-size: 16px;">{{ item.address }}
                        </div>
                        <div class="li_item_right"> {{ $t('lang.发布时间：') }}{{ item.created_time }}</div>
                    </div>
                    <div class="li_item">
                        <div class="li_item_left">{{ $t('lang.数量：') }}{{ item.remain_amount }}</div>
                        <div class="li_item_right">{{ $t('lang.单价') }}</div>
                    </div>
                    <div class="li_item">
                        <div class="li_item_left">{{ $t('lang.支付方式：') }} <span
                                style="color: rgba(0, 0, 0, 1);font-size: 16px;">ONE</span></div>
                        <div class="li_item_right" style="color: rgba(0, 0, 0, 1);font-size: 16px;">{{ item.price }}
                        </div>
                    </div>
                    <div class="li_item">
                        <div class="li_item_left"><img src="@/assets/imgsnowball/c2c_li_logo.png" alt=""></div>
                        <div class="li_item_button" @click="sellshowFun(item)"><span v-if="tabsactive == 0">{{
                $t('lang.卖出') }}</span>
                            <span v-else>{{ $t('lang.买入') }}</span>
                        </div>

                    </div>
                </div>
            </van-list>

        </div>
        <div class="nav_bottom">
            <div class="nav_bottom_left">
                <div style="font-size: 20px; color: rgba(0, 0, 0, 1);">{{ lastPrice }}</div>
                <div style="font-size: 12px;"> {{ $t('lang.最新成交价') }}</div>
            </div>
            <div class="nav_bottom_right">
                <van-button type="primary" loading-type="spinner" class="button" @click="buyshowFun('0')">
                    {{ $t('lang.发布买单') }}
                </van-button>
                <!-- <van-button type="primary" loading-type="spinner" class="button"
                    style="background: rgba(102, 180, 174, 1); border: none;" @click="buyshowFun('1')">
                    {{ $t('lang.发布卖单') }}
                </van-button> -->
            </div>
        </div>
        <van-popup v-model="timerChangeShow.BooleanShow" round position="bottom">
            <van-datetime-picker v-model="filterTimer.DateTimeData" type="datehour" :title="$t('lang.选择年月日时')"
                :min-date="filterTimer.minDate" :max-date="filterTimer.maxDate" @confirm="filterTimeronConfirm"
                @cancel="filterTimeronCancel" />
        </van-popup>
        <van-popup v-model="buyshow" round position="bottom" closeable>
            <div class="buyshow">
                <div class="title" style="font-size: 16px;margin-bottom: 13px;">
                    <span v-if="buySellCheck == '0'"> {{ $t('lang.发布买单') }}</span>
                    <span v-else-if="buySellCheck == '1'">{{ $t('lang.发布卖单') }}</span>

                </div>
                <div class="title">
                    <span v-if="buySellCheck == '0'">{{ $t('lang.购买数量') }}</span>
                    <span v-else-if="buySellCheck == '1'"> {{ $t('lang.卖出数量') }}</span>

                </div>
                <div class="inputBox">
                    <input type="number" v-model="buyNum"
                        :placeholder="buySellCheck == '0' ? `${$t('lang.请输入购买数量')} ${zoneInfo.tradeItem.min_amount} - ${zoneInfo.tradeItem.max_amount} ` : `${$t('lang.请输入卖出数量')}${zoneInfo.tradeItem.min_amount} - ${zoneInfo.tradeItem.max_amount} `">
                    <div class="inputBox_right">星石</div>
                </div>
                <div class="title">
                    <span v-if="buySellCheck == '0'">{{ $t('lang.购买单价') }} </span>
                    <span v-else-if="buySellCheck == '1'">{{ $t('lang.卖出单价') }}</span>
                </div>
                <div class="inputBox">
                    <input type="number" v-model="buyPrice"
                        :placeholder="buySellCheck == '0' ? `${$t('lang.请输入购买单价')}${zoneInfo.tradeItem.min_price}- ${zoneInfo.tradeItem.max_price}` : `${$t('lang.请输入卖出单价')}${zoneInfo.tradeItem.min_price}- ${zoneInfo.tradeItem.max_price}`">
                    <div class="inputBox_right">ONE</div>
                </div>
                <div class="title">
                    <span v-if="buySellCheck == '0'">{{ $t('lang.购买总价') }}</span>
                    <span v-else-if="buySellCheck == '1'">{{ $t('lang.卖出总价') }}</span>
                </div>
                <div class="inputBox">
                    <div>{{ ComBuySellsSum }}</div>
                    <div class="inputBox_right">ONE</div>
                </div>

                <div style="color: rgba(0, 0, 0, 0.4); font-size: 12px;">
                    <span v-if="buySellCheck == '0'">
                        {{ $t('lang.账户余额') }} :{{ ONEbanlace.usable }} ONE
                    </span>
                    <span v-else>
                        {{ $t('lang.账户余额') }} :{{ SSbanlace.usable }} 星石
                    </span>
                </div>
                <div style="color: rgba(102, 180, 174, 1); font-size: 12px;">{{ $t('lang.说明：交易星石收取税收手续费，买入星石收取') }}{{
                zoneInfo.tradeItem.buy_fee_rate * 100 }}% {{ $t('lang.税收，卖出星石收取') }}{{
                zoneInfo.tradeItem.sell_fee_rate * 100
            }}%{{ $t('lang.税收') }}
                </div>
                <div class="buyshow_button" @click="c2cmarketpublish">{{ $t('lang.确定') }}</div>
            </div>
        </van-popup>
        <van-popup v-model="sellshow" round position="bottom" closeable>
            <div class="buyshow">
                <div class="title" style="font-size: 16px;margin-bottom: 13px;">
                    <span v-if="tabsactive == '0'">
                        {{ $t('lang.卖出') }} 星石
                    </span>
                    <span v-else>
                        {{ $t('lang.买入') }} 星石
                    </span>

                </div>
                <div style="color: rgba(0, 0, 0, 0.4); font-size: 12px;">{{ $t('lang.单价') }}:{{ buysellItem.price }}
                </div>
                <div class="inputBox" style="border-radius: 77px;margin-top: 18.5px;">
                    <input type="number" placeholder="0.00" v-model="buysellInput">
                    <div class="inputBox_right" @click="allAmount(buysellItem.remain_amount)">{{ $t('lang.全部') }}</div>
                </div>
                <div style="color: rgba(0, 0, 0, 0.4); font-size: 12px; margin-bottom: 6px;">{{ $t('lang.当前剩余可交易数量：')
                    }}{{
                buysellItem.remain_amount }}</div>
                <div style="color: rgba(0, 0, 0, 0.4); font-size: 12px;">
                    <span v-if="tabsactive == '0'">{{ $t('lang.个人账户数量余额：') }} {{ SSbanlace.usable }} 星石</span>
                    <span v-else>{{ $t('lang.个人账户数量余额：') }} {{ ONEbanlace.usable }} ONE</span>
                </div>

                <div style="color: rgba(0, 0, 0, 0.4); font-size: 12px; margin-top: 24px;">
                    <span v-if="tabsactive == 0">
                        {{ $t('lang.可得：') }}
                    </span>
                    <span v-else>
                        {{ $t('lang.需支付') }}:
                    </span>


                    <span style="font-size: 16px;color: rgba(0, 0, 0, 1);">{{ ComBuySellONENum }}</span> ONE
                </div>
                <div style="color: rgba(102, 180, 174, 1); font-size: 12px;">{{ $t('lang.说明：交易星石收取税收手续费，买入星石收取') }}{{
                    zoneInfo.tradeItem.buy_fee_rate * 100 }}% {{ $t('lang.税收，卖出星石收取') }}{{
                    zoneInfo.tradeItem.sell_fee_rate * 100
                    }}%{{ $t('lang.税收') }}
                </div>
                <div class="buyshow_button" @click="BuySellFun"> {{ $t('lang.确定') }}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { c2c, nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    data() {
        return {
            filterTimer: {
                minDate: new Date(2024, 6, 1),
                maxDate: new Date(),
                minTime: '',
                maxTime: '',
                DateTimeData: '',
            },
            timerChangeShow: {
                status: 'min',
                BooleanShow: false,
            },
            filter: {
                minInput: '',
                maxInput: '',
            },
            buysellInput: 0,
            buyNum: 0,
            buyPrice: 0,
            buyshow: false,
            sellshow: false,
            tabsactive: 0,
            value1: 0,
            value2: 0,
            title1: this.$t('lang.购买价格'),
            title2: this.$t('lang.发布时间'),
            option1: [
                { text: '100', value: 0 },
                { text: '500', value: 1 },
                { text: '1000', value: 2 },
            ],
            option2: [
                { text: '近7天', value: 0 },
                { text: '近15天', value: 1 },
                { text: '近一月', value: 2 },
            ],
            c2clist: {
                loading: false,
                finished: false,
                list: [],
                info: {
                    page: 1,
                    page_size: 10,
                    type: 0
                }

            },
            ONEbanlace: {
                usable: 0
            },
            SSbanlace: {
                usable: 0
            },
            buySellCheck: '0',
            zoneInfo: {
                token_list: [],
                trade_map: {},
                trade_type: [],
                tokenItem: {},
                tradeItem: {},
            },
            lastPrice: 0,
            buysellItem: {},
            assetsList: [],
            assetsLoading: false
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
        ComBuySellsSum() {
            if (this.buyNum && this.buyPrice) {
                if (this.buySellCheck == '0') {
                    return parseFloat((this.buyNum * this.buyPrice) - (this.buyNum * this.buyPrice * this.zoneInfo.tradeItem.buy_fee_rate)).toFixed(2)
                } else {
                    return parseFloat((this.buyNum * this.buyPrice) - (this.buyNum * this.buyPrice * this.zoneInfo.tradeItem.sell_fee_rate)).toFixed(2)
                }

            } else {
                return this.$t('lang.自动计算')
            }

        },

        ComBuySellONENum() {
            if (this.buysellInput) {
                if (this.tabsactive == '1') {
                    return parseFloat((this.buysellInput * this.buysellItem.price) - (this.buysellInput * this.buysellItem.price * this.zoneInfo.tradeItem.buy_fee_rate)).toFixed(2)
                } else {
                    return parseFloat((this.buysellInput * this.buysellItem.price) - (this.buysellInput * this.buysellItem.price * this.zoneInfo.tradeItem.sell_fee_rate)).toFixed(2)
                }
            } else {
                return 0.00
            }
        }
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    components: {

    },
    mounted() {

    },
    created() {

        this.init()
    },
    methods: {
        onLoadList() {
            this.c2clist.info.page++
            this.c2cmarketlist()

        },
        c2cassets() {
            c2c.c2cassets(this.account).then(res => {
                if (res.code == 200) {
                    this.assetsList = res.data
                }
                this.assetsLoading = false

            })
        },
        timerChangeShowFun(status) {
            this.timerChangeShow.status = status
            this.timerChangeShow.BooleanShow = true
        },
        filtercanncel() {
            this.$refs.dropdownitem.toggle(false);
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        filterTimeronConfirm(value) {
            if (this.timerChangeShow.status == 'min') {
                this.filterTimer.minTime = this.formatDate(value);
            } else {
                this.filterTimer.maxTime = this.formatDate(value);
            }
            this.timerChangeShow.BooleanShow = false
        },
        filterTimeronCancel() {

            this.timerChangeShow.BooleanShow = false
        },
        filterclear() {
            this.filter.minInput = '';
            this.filter.maxInput = '';
            delete this.c2clist.info.min_price;
            delete this.c2clist.info.max_price;
            this.title1 = this.$t('lang.购买价格');
            this.initMarketListFun()
            this.filtercanncel()
        },
        filtercanncelTime() {
            this.$refs.dropdownitemTime.toggle(false);
        },
        filterclearTime() {
            this.filterTimer.minTime = '';
            this.filterTimer.maxTime = '';
            delete this.c2clist.info.begin_time;
            delete this.c2clist.info.end_time;
            this.title2 = this.$t('lang.发布时间');
            this.initMarketListFun()
            this.filtercanncelTime()
        },
        filterTimeFun() {
            if (this.filterTimer.minTime == '' || this.filterTimer.maxTime == '') {
                return this.$toast(this.$t('lang.请选择发布时间区间'))
            }
            if (this.isSecondDateLater(this.filterTimer.minTime, this.filterTimer.maxTime)) {
                return this.$toast(this.$t('lang.请选择正确的发布时间区间'))
            }
            this.c2clist.info.begin_time = this.filterTimer.minTime;
            this.c2clist.info.end_time = this.filterTimer.maxTime;
            this.title2 = this.filterTimer.minTime + '-' + this.filterTimer.maxTime
            this.initMarketListFun()
            this.filtercanncelTime()
        },
        isSecondDateLater(date1Str, date2Str) {
            const date1 = new Date(date1Str);
            const date2 = new Date(date2Str);

            return date1 > date2;
        },
        initMarketListFun() {
            this.c2clist.loading = false
            this.c2clist.finished = false
            this.c2clist.list = []
            this.c2clist.info.page = 1
            this.c2clist.info.page_size = 10
            this.c2cmarketlist()
        },

        filterPriceFun() {
            if (this.filter.minInput == '' || this.filter.maxInput == '') {
                return this.$toast(this.$t('lang.请输入最小最大价格'))
            }
            if (this.filter.minInput > this.filter.maxInput) {
                return this.$toast(this.$t('lang.请输入正确价格'))
            }


            this.c2clist.info.min_price = parseFloat(this.filter.minInput);
            this.c2clist.info.max_price = parseFloat(this.filter.maxInput);
            this.title1 = this.filter.minInput + '~' + this.filter.maxInput
            this.initMarketListFun()
            this.filtercanncel()
        },
        Goc2c2Order() {
            this.$router.push({ name: 'c2c2Order' });
        },
        toprouter() {
            this.$router.go(-1);

        },
        BuySellFun() {
            this.sellshow = false
            if (this.tabsactive == '0') {
                this.c2cmarketsell()
            } else {
                this.c2cmarketbuy()
            }
        },
        async c2cmarketsell() {
            let sign = null
            try {
                let web3 = new Web3(window.ethereum);
                sign = await web3.eth.personal.sign(web3.utils.fromUtf8('C2C Sell'), this.account);
            } catch (error) {
                return;
            }
            let data = {
                id: this.buysellItem.id,
                amount: parseFloat(this.buysellInput),
                signature: sign
            }
            c2c.c2cmarketsell(data, this.account).then(res => {
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        async c2cmarketbuy() {
            let sign = null
            try {
                let web3 = new Web3(window.ethereum);
                sign = await web3.eth.personal.sign(web3.utils.fromUtf8('C2C Buy'), this.account);
            } catch (error) {
                return;
            }
            let data = {
                id: this.buysellItem.id,
                amount: parseFloat(this.buysellInput),
                signature: sign
            }
            c2c.c2cmarketbuy(data, this.account).then(res => {
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        buyshowFun(index) {
            this.buyNum = ''
            this.buyPrice = ''
            this.buySellCheck = index
            this.buyshow = true
        },
        allAmount(num) {
            this.buysellInput = num
        },
        sellshowFun(item) {
            this.buysellInput = ''
            this.buysellItem = item
            this.sellshow = true
        },
        tabsactiveFun(index) {
            this.tabsactive = index
            this.initMarketListFun()
            this.c2clist.info.type = this.tabsactive
            this.c2cmarketlist()
        },
        checkFun1(value) {
            this.title1 = this.option1[value].text
        },
        checkFun2(value) {
            this.title2 = this.option2[value].text
        },
        async c2cmarketpublish() {
            if (!this.buyNum || !this.buyPrice) {
                this.$toast(this.$t('lang.请输入数量和价格'))
                return
            }

            let sign = null
            try {
                let web3 = new Web3(window.ethereum);
                sign = await web3.eth.personal.sign(web3.utils.fromUtf8('C2C Publish'), this.account);
            } catch (error) {
                return;
            }
            let data = {
                conf_id: parseInt(this.zoneInfo.tradeItem.id),
                type: parseInt(this.buySellCheck),
                amount: parseFloat(this.buyNum),
                price: parseFloat(this.buyPrice),
                signature: sign
            }
            c2c.c2cmarketpublish(data, this.account).then(res => {
                this.buyshow = false
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        init() {

            if (this.account) {
                const now = new Date()
                this.filterTimer.maxDate = new Date(now.getFullYear(), now.getMonth() + 2, now.getDay())
                this.initMarketListFun()
                this.c2clist.info.type = this.c2clist.info.type
            }
            this.assetsLoading = true
            this.c2cmarketlist()
            this.c2czone()
            this.getbanlace()
            this.c2cmarketlatestprice()
            this.c2cassets()
        },
        c2czone() {
            c2c.c2czone(this.account).then(res => {
                if (res.code == 200) {
                    this.zoneInfo = res.data
                    this.tokenItem = this.zoneInfo.token_list.find(item => item.token_key == 'ONE')
                    let tradList = this.zoneInfo.trade_map[this.tokenItem.token_key]
                    this.zoneInfo.tradeItem = tradList.find(item => item.t1_key == 'SS')
                }
            })
        },
        getbanlace() {
            nft.walletinfobykey({ token_key: 'ONE' }, this.account).then(res => {
                if (res.code == 200) {
                    this.ONEbanlace = res.data
                }

            })
            nft.walletinfobykey({ token_key: 'SS' }, this.account).then(res => {
                if (res.code == 200) {
                    this.SSbanlace = res.data
                }
            })

        },
        c2cmarketlatestprice() {
            let data = {
                token_id: 11
            }
            c2c.c2cmarketlatestprice(data, this.account).then(res => {
                if (res.code == 200) {
                    this.lastPrice = res.data
                }
            })
        },
        c2cmarketlist() {
            this.c2clist.loading = true
            c2c.c2cmarketlist(this.c2clist.info, this.account).then(res => {

                if (res.data.list != null || res.data.list.length > 0) {
                    if (this.c2clist.info.page == 1) {
                        this.c2clist.list = res.data.list
                    } else {
                        this.c2clist.list = this.c2clist.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.c2clist.info.page_size) {

                        this.c2clist.finished = true
                    } else {
                       
                    }

                }
                this.c2clist.loading = false


            })
        },
    },
}

</script>
<style scoped lang='less'>
.c2c_box {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 335px;
    height: 141px;
    opacity: 1;
    border-radius: 15.45px;
    background: url('~@/assets/img/c2c_box_bg.png') no-repeat;
    background-size: contain;
    padding: 17px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #fff;

    .c2c_box_line {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &>div {
            flex: 1;
            text-align: center;
        }

        &>div:nth-child(1) {
            text-align: left;
        }

        &>div:nth-child(2) {
            text-align: center;
        }

        &>div:nth-child(3) {
            text-align: right;
        }
    }
}

.dropdown_item {
    padding: 20px;

    .dropdown_item_line {
        margin-bottom: 20px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        input,
        .dropdown_item_line_box {
            border: none;
            width: 40%;
            background: rgba(243, 243, 243, 1);
            padding: 10px 5px;
            border-radius: 8px;
            font-size: 12px;
            text-align: center;
        }
    }
}

.buyshow {
    padding: 16px 20px;
    width: 100%;

    .title {
        font-size: 12px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 8px;
    }

    .buyshow_button {
        text-align: center;
        padding: 13px 0;
        border-radius: 101px;
        background: rgba(102, 180, 174, 1);
        font-size: 16px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        margin-top: 12px;
    }

    .inputBox {
        margin-bottom: 18px;
        padding: 14px 22px;
        border-radius: 8px;
        background: rgba(243, 243, 243, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;

        input {
            border: none;
            background: none;

        }

        .inputBox_right {
            padding-left: 21px;
            border-left: 1px solid rgba(210, 210, 210, 1);
        }
    }
}

.nav_bottom {
    width: 100%;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 74px;
    left: 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

    .nav_bottom {
        font-size: 12px;
        font-weight: 700;
    }

    .nav_bottom_right {
        display: flex;
        align-items: center;
        // width: 55%;
        justify-content: space-between;

        .button {
            border-radius: 113px;
            background: rgba(42, 130, 228, 1);
            color: rgba(255, 255, 255, 1) !important;
        }
    }
}

/deep/.van-dropdown-menu__bar {
    background: none;
    box-shadow: none;
}

.checks {
    margin: 20px 0;
}

/deep/.van-dropdown-item__content {
    padding: 10px;
}

/deep/.van-dropdown-item__option {
    padding: 10px 0 !important;
    border-bottom: 1px solid rgba(245, 245, 245, 1);
}

.c2c {
    padding-top: 52px;
    padding-bottom: 44px;
    background: rgba(255, 255, 255, 1);
}

.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .tabs_right {
        font-size: 12px;
    }

    .tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 25%;

        .tabs_item {
            font-size: 14px;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
        }

        .tabs-active {
            font-size: 18px;
            font-weight: 700;
            color: rgba(34, 34, 34, 1);
            position: relative;

            &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                transition: .5s;
                bottom: -5px;
                content: "";
                width: 29px;
                height: 3px;
                opacity: 1;
                border-radius: 16px;
                background: rgba(102, 180, 174, 1);
            }
        }
    }
}

.list {
    min-height: 500px;
    max-height: 500px;
    overflow-y: scroll;
    padding: 17px 18px;
    padding-bottom: 50px;
    border-top: 1px solid rgba(245, 245, 245, 1);
    width: 100%;

    .li {
        padding: 19.5px 14px;
        border-radius: 10px;
        margin-bottom: 14px;
        border: 1px solid rgba(240, 240, 240, 1);
        height: 174px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .li_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            img {
                width: 30px;
                height: 30px;
            }

            .li_item_button {
                padding: 6px 28px;
                border-radius: 74px;
                background: rgba(102, 180, 174, 1);
                font-size: 16px;
                font-weight: 700;
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}
</style>