import { render, staticRenderFns } from "./xiangqin.vue?vue&type=template&id=e93e49f6&scoped=true"
import script from "./xiangqin.vue?vue&type=script&lang=js"
export * from "./xiangqin.vue?vue&type=script&lang=js"
import style0 from "./xiangqin.vue?vue&type=style&index=0&id=e93e49f6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e93e49f6",
  null
  
)

export default component.exports